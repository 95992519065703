<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName($route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @delete-item="deleteItem"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <span
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row['Alias ID'])"
          >
            <font-awesome-icon icon="trash" />
          </span>
        </div>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    invoiceId: {
      type: String,
      default: ""
    }
  },
  name: "ProductsTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Product Name",
            "Bottle Size",
            "Base Product",
            "Category",
            "Quantity",
            "Unit Price",
            "Alias ID",
            "Alias",
            "States",
            "User",
            "Created",
            "Actions"
          ],
          filterable: [
            "ID",
            "Product Name",
            "Bottle Size",
            "Base Product",
            "Category",
            "Quantity",
            "Unit Price",
            "Alias",
            "States",
            "User",
            "Created",
            "Actions"
          ],
          formatColumns: [
            {
              name: "Unit Price",
              style: {
                style: "currency",
                currency: "USD"
              }
            },
            {
              name: "Qty/Unit",
              style: {
                style: "decimal"
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showCustomActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },

    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      try {
        response = await this.$api.post("products/aliases", payload);

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Product submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Product submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Product submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item["Alias ID"] === id);

      let confirm = await this.$form.showConfirmation(
        `Alias #${item["Alias ID"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`products/aliases/${item["Alias ID"]}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData();
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    }
  },
  watch: {}
};
</script>

<style scoped></style>
