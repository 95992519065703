var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "product-aliases",
      "load-dictionaries": _vm.loadDictionaries,
      "show-labels": false,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dataTable.visible,
      expression: "dataTable.visible"
    }]
  }, [_c('b-col', [_c('product-aliases-table', {
    ref: "product-aliases"
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }